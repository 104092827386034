import React from "react";
import {
	Box,
	Button, Checkbox,
	Divider,
	FormControl, FormControlLabel,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Typography
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import {
	Add as AddIcon,
	Delete as DeleteIcon,

	KeyboardArrowUp as KeyboardArrowUpIcon,
	KeyboardArrowDown as KeyboardArrowDownIcon,
} from "@mui/icons-material";
import {
	DialogConfirmation
} from "../../../../../components";
import ExtraOptionRow from "./ExtraOptionRow";
import clsx from "clsx";
import {IMaskInput} from "react-imask";
import FlatList from "flatlist-react";
import agent from "../../../../../agent/agent";
import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import {numberFormat} from "../../../../../common/Formater";

class ExtraOption extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			form: {}
		}
	};

	createNewOption = () => {
		this.props.handleAddOption(this.props.idxExtraOption);
	}
	removeOption = () => {
		this.props.removeOption(this.props.idxExtraOption)
	}
	changeFormMain = (event) => {
		this.props.changeFormMain(event, this.props.idxExtraOption)
	}

	multiChangePriceChart = (variantIds, value) => {
		const extraOption = JSON.parse(this.props.extraOption);

		let variants = [...extraOption.variants];
		variants = variants.map((variant) => {
			if (variantIds.includes(variant.id)) {
				variant.price_old_value = value;
				variant.option_preset_disable_sync_prices = 1;
			}

			return variant
		});


		this.props.changeFormFullExtraOptions(variants, this.props.idxExtraOption);
	}
	multiChangePriceDiscountChart = (variantIds, value) => {
		const extraOption = JSON.parse(this.props.extraOption);

		let variants = [...extraOption.variants];
		variants = variants.map((variant) => {
			if (variantIds.includes(variant.id)) {
				variant.price_value = value;
				variant.option_preset_disable_sync_prices = 1;
			}

			return variant
		});


		this.props.changeFormFullExtraOptions(variants, this.props.idxExtraOption);
	}
	multiChangeStatusChart = (variantIds, value) => {
		const extraOption = JSON.parse(this.props.extraOption);

		let variants = [...extraOption.variants];
		variants = variants.map((variant) => {
			if (variantIds.includes(variant.id)) {
				variant.status = Number(value);
				variant.option_preset_disable_sync_status = 1;
			}

			return variant
		});

		this.props.changeFormFullExtraOptions(variants, this.props.idxExtraOption);
	}

	render() {
		const {
			errors,
			idxExtraOption,
			isOptionPresetGroup,

			totalCountOptionPresetRows
		} = this.props;
		const extraOption = JSON.parse(this.props.extraOption);
		const errorVariant = errors[idxExtraOption] || {};

		if (isOptionPresetGroup) {
			return (
				<MultiSection
					{...this}
					totalCountOptionPresetRows={totalCountOptionPresetRows}
					extraOption={extraOption}
					errorVariant={errorVariant}
				/>
			)
		}
		return (
			<DefaultSection
				{...this}
				extraOption={extraOption}
				errorVariant={errorVariant}
			/>
		)
	}
}


const DefaultSection = React.memo((self) => {
	const {
		props,
		extraOption,
		errorVariant
	} = self;
	const {
		errors,
		touched,
		classes,
		isDisabledEdit,
		idxExtraOption
	} = props || {};
	const [isVisibleTable, setVisibleTable] = React.useState(false);
	const [isVisibleDimensions, setVisibleDimensions] = React.useState(false);
	const [isVisibleLogisticianData, setVisibleLogisticianData] = React.useState(false);
	const IconButtonVisible = Boolean(isVisibleTable) ? KeyboardArrowUpIcon : KeyboardArrowDownIcon;

	React.useEffect(() => {
		const errorItem = errors?.[idxExtraOption] || {};
		const errorItemLength = Object.keys(errorItem).length;

		if (errorItemLength > 0) {
			setVisibleTable(true);
		}
	}, [errors]);

	return (
		<Box p={2} bgcolor="rgba(0,0,0,0.05)" borderRadius={2}>
			<Grid container spacing={2} wrap="nowrap">
				<Grid item xs={2.5}>
					<TextField
						value={extraOption.name}
						error={Boolean(errorVariant.name && errorVariant.name)}
						helperText={errorVariant.name && errorVariant.name}
						label="Наименование *"
						name="name"
						size="small"
						disabled={isDisabledEdit}
						onChange={self.changeFormMain}
						fullWidth
					/>
				</Grid>
				<Grid item xs={1.5}>
					<FormControl
						fullWidth
						disabled={isDisabledEdit}
						error={Boolean(touched.status && errors.status)}
					>
						<InputLabel>Статус</InputLabel>
						<Select
							value={extraOption.status}
							error={Boolean(touched.status && errors.status)}
							helperText={touched.status && errors.status}
							label="Статус"
							name="status"
							fullWidth
							onChange={self.changeFormMain}
						>
							<MenuItem value={0}>Скрыт</MenuItem>
							<MenuItem value={1}>Активен</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={1}>
					<TextField
						value={extraOption.sort}
						error={Boolean(errorVariant.sort && errorVariant.sort)}
						helperText={errorVariant.sort && errorVariant.sort}
						disabled={isDisabledEdit}
						label="Сортировка"
						name="sort"
						size="small"
						type="number"
						onChange={self.changeFormMain}
						fullWidth
					/>
				</Grid>
				<Grid item>
					<FormControlLabel
						control={<Checkbox />}
						label="Показать логист. данные"
						checked={isVisibleLogisticianData}
						onChange={(event, value) => setVisibleLogisticianData(value)}
					/>
				</Grid>
				<Grid item>
					<FormControlLabel
						control={<Checkbox />}
						label="Показать габариты"
						checked={isVisibleDimensions}
						onChange={(event, value) => setVisibleDimensions(value)}
					/>
				</Grid>
				<Grid item className={classes.controlsActions}>
					<Grid container spacing={1}>
						<Grid item>
							<Button
								className={clsx([classes.controlsAction, classes.controlsActionText])}
								variant="outlined"
								disabled={isDisabledEdit}
								onClick={props.handleCopyOption.bind(self, idxExtraOption)}
							>
								<AddIcon/>
								Копировать
							</Button>
						</Grid>
						<Grid item>
							<Button
								className={classes.controlsAction}
								variant="outlined"
								disabled={isDisabledEdit}
								onClick={self.createNewOption}
							>
								<AddIcon/>
							</Button>
						</Grid>
						<Grid item>
							<Button
								className={classes.controlsAction}
								variant="outlined"
								disabled={isDisabledEdit}
								onClick={() => setVisibleTable(!isVisibleTable)}
							>
								<IconButtonVisible/>
							</Button>
						</Grid>
						<Grid item>
							<Button disabled={isDisabledEdit} className={classes.controlsAction} variant="outlined"
							        onClick={self.removeOption}>
								<DeleteIcon/>
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			{Boolean(isVisibleTable) && (
				<Table className={classes.table} sx={{marginTop: 2}}>
					<TableHead>
						<TableRow>
							<TableCell width={30}>Статус</TableCell>
							<TableCell>Заголовок</TableCell>
							<TableCell>Цена со скидкой</TableCell>
							<TableCell>Базовая цена</TableCell>
							<TableCell>Цена в ЕВРО</TableCell>
							<TableCell>Тип цены</TableCell>
							<TableCell>Артикул</TableCell>
							<TableCell>Сорт.</TableCell>
							<TableCell width="38px">Отд.<br/>товар</TableCell>

							{Boolean(isVisibleLogisticianData) && (
								<>
									<TableCell>Объем упаковки, м3</TableCell>
									<TableCell>Вес упаковки, кг</TableCell>
									<TableCell>Кол-во мест трансп.</TableCell>
								</>
							)}

							{Boolean(isVisibleDimensions) && (
								<>
									<TableCell>Ш, мм</TableCell>
									<TableCell>В, мм</TableCell>
									<TableCell>Г, мм</TableCell>
									<TableCell>Д, мм</TableCell>
								</>
							)}

							<TableCell width="84px">Изображение</TableCell>
							<TableCell align="right"/>
						</TableRow>
					</TableHead>
					<TableBody>
						{(extraOption.variants || []).map((option, idxOption) => (
							<ExtraOptionRow
								key={`variants-${idxExtraOption}-${idxOption}`}
								option={JSON.stringify(option)}
								errorVariant={errorVariant}
								extraOption={extraOption}

								disabledForm={isDisabledEdit}
								disabledFormPrice={props.isDisabledEditPrice}

								idxExtraOption={idxExtraOption}
								idxOption={idxOption}
								isOptionPresetGroup={props.isOptionPresetGroup}
								isVisibleDimensions={isVisibleDimensions}
								isVisibleLogisticianData={isVisibleLogisticianData}

								onChange={props.handleChangeOption}
								onDelete={props.handleDeleteOption}
							/>
						))}
					</TableBody>
				</Table>
			)}
		</Box>
	)
});
const MultiSection = React.memo((self) => {
	const {
		props,
		extraOption,
		errorVariant,
		totalCountOptionPresetRows
	} = self;
	const {
		errors,
		touched,
		classes,
		isDisabledEdit,
		idxExtraOption
	} = props || {};
	const [categoriesFabrics, setCategoriesFabrics] = React.useState([]);
	const [dataStructure, setDataStructure] = React.useState([]);
	const [isVisibleDimensions, setVisibleDimensions] = React.useState(false);
	const [isVisibleLogisticianData, setVisibleLogisticianData] = React.useState(false);
	const isDisabledRemove = Boolean(isDisabledEdit && totalCountOptionPresetRows <= 1 && false);

	React.useEffect(() => {
		(async () => {
			await getCategoriesFabrics();
			// await getDataStructure();
		})();
	}, []);

	const getCategoriesFabrics = async () => {
		const adminGroupCharacteristicId = extraOption?.admin_group_characteristic_id;
		let response = await agent.get(`/api/admin/products/characteristic-variants-by-id/${adminGroupCharacteristicId}`).then((res) => {
			return res.data
		}).catch(() => {
			return []
		});

		response = response.sort((a, b) => {
			if (a.sort < b.sort) {
				return -1
			}
			if (a.sort > b.sort) {
				return 1
			}
			return 0
		});

		setCategoriesFabrics(response);

		await getDataStructure(response);
	}
	const getDataStructure = async (categoriesFabrics) => {
		const extraOptionVariants = extraOption?.variants || [];
		const newDataStructure = categoriesFabrics.map((category) => {
			const variants = extraOptionVariants
				.filter((t) => (t.extraCharacteristicVariants || []).includes(category.id))
				.map((variant) => {
					return {
						...variant,
						idxOption: extraOptionVariants.findIndex((t) => t.id === variant.id),
						idxExtraOption: idxExtraOption
					}
				});

			return {
				...category,
				variants
			}
		}).filter((t) => (t.variants || []).length > 0);

		setDataStructure(newDataStructure);
	}


	const _title = () => {
		return `Заполнено из шаблона №${extraOption?.option_preset_option_id} (подшаблон №${extraOption.option_preset_option_group_id})`
		return `Варианты заполнены из шаблона №${extraOption.option_preset_option_group_id}`
	}

	return (
		<Box p={2} bgcolor="rgba(0,0,0,0.05)" borderRadius={2}>

			<Box mb={4}>
				<Typography variant="h5">{_title()}</Typography>
			</Box>

			<Grid container spacing={2} wrap="nowrap">
				<Grid item sx={{flex: 1}} container spacing={2}>
					<Grid item xs={3}>
						<TextField
							value={extraOption.name}
							error={Boolean(errorVariant.name && errorVariant.name)}
							helperText={errorVariant.name && errorVariant.name}
							label="Наименование *"
							name="name"
							size="small"
							disabled={isDisabledEdit}
							onChange={self.changeFormMain}
							fullWidth
						/>
					</Grid>
					<Grid item xs={3}>
						<FormControl
							fullWidth
							disabled={isDisabledEdit}
							error={Boolean(touched.status && errors.status)}
						>
							<InputLabel>Статус</InputLabel>
							<Select
								value={extraOption.status}
								error={Boolean(touched.status && errors.status)}
								helperText={touched.status && errors.status}
								label="Статус"
								name="status"
								fullWidth
								onChange={self.changeFormMain}
							>
								<MenuItem value={0}>Скрыт</MenuItem>
								<MenuItem value={1}>Активен</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={2}>
						<TextField
							value={extraOption.sort}
							error={Boolean(errorVariant.sort && errorVariant.sort)}
							helperText={errorVariant.sort && errorVariant.sort}
							disabled={isDisabledEdit}
							label="Сортировка"
							name="sort"
							size="small"
							type="number"
							onChange={self.changeFormMain}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} container>
						<Grid item>
							<FormControlLabel
								control={<Checkbox />}
								disabled={true}
								label="Показать логист. данные"
								checked={isVisibleLogisticianData}
								onChange={(event, value) => setVisibleLogisticianData(value)}
							/>
						</Grid>
						<Grid item>
							<FormControlLabel
								control={<Checkbox />}
								disabled={true}
								label="Показать габариты"
								checked={isVisibleDimensions}
								onChange={(event, value) => setVisibleDimensions(value)}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item className={classes.controlsActions}>
					<Grid container spacing={2}>
						<Grid item>
							<Button
								className={clsx([classes.controlsAction, classes.controlsActionText])}
								variant="outlined"
								disabled={isDisabledEdit}
								// onClick={handleCopyOption.bind(this, idxExtraOption)}
							>
								<AddIcon/>
								Копировать
							</Button>
						</Grid>
						<Grid item>
							<Button
								className={classes.controlsAction}
								variant="outlined"
								disabled={isDisabledEdit}
								onClick={self.createNewOption}
							>
								<AddIcon/>
							</Button>
						</Grid>
						<Grid item>
							<Button disabled={isDisabledRemove} className={classes.controlsAction} variant="outlined"
							        onClick={self.removeOption}>
								<DeleteIcon/>
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<Box mt={4}/>

			<Grid container spacing={2}>
				{dataStructure.map((item, index) => {
					const extraOptionVariants = (extraOption?.variants || []);
					const itemVariants = (item.variants || []).map((variant, index) => {
						const extraOptionVariant = extraOptionVariants.find((t) => t.id === variant.id);
						const variantIndex = extraOptionVariants.findIndex(((t) => t.id === variant.id));

						return {
							...variant,
							...extraOptionVariant,
							variantIndex: variantIndex
						}
					});

					return (
						<>
							{Boolean(index > 0) && (
								<Divider sx={{width: "100%", height: "1px", background: "black"}}/>
							)}

							<Grid item xs={12}>
								<VariantGroup
									item={item}
									variants={itemVariants}
									classes={classes}
									extraOption={extraOption}

									errorVariant={errorVariant}
									isDisabledEdit={isDisabledEdit}
									isDisabledEditPrice={props.isDisabledEditPrice}
									idxExtraOption={idxExtraOption}
									isOptionPresetGroup={props.isOptionPresetGroup}
									handleChangeOption={props.handleChangeOption}
									handleDeleteOption={props.handleDeleteOption}
									changeVisibleVariantGroups={self.changeVisibleVariantGroups}

									isVisibleDimensions={isVisibleDimensions}
									isVisibleLogisticianData={isVisibleLogisticianData}

									onMultiChangePriceChart={self.multiChangePriceChart}
									onMultiChangePriceDiscountChart={self.multiChangePriceDiscountChart}
									onMultiChangeStatusChart={self.multiChangeStatusChart}
								/>
							</Grid>
						</>
					)
				})}
			</Grid>
		</Box>
	)
})
const VariantGroup = React.memo((props) => {
	const {
		item,
		variants,
		extraOption,

		classes,


		errorVariant,
		isDisabledEdit,
		isDisabledEditPrice,
		idxExtraOption,
		isOptionPresetGroup,
		handleChangeOption,
		handleDeleteOption,

		isVisibleDimensions,
		isVisibleLogisticianData,

		onMultiChangePriceChart,
		onMultiChangePriceDiscountChart,
		onMultiChangeStatusChart
	} = props;
	const [isOpen, setIsOpen] = React.useState(false);
	const [newPrice, setNewPrice] = React.useState('');
	const [newPriceDiscount, setNewPriceDiscount] = React.useState('');
	const [errorsObject, setErrorsObject] = React.useState({});
  const [basePrice, setBasePrice] = React.useState("");
  const [discountPrice, setDiscountPrice] = React.useState("");
	const refDialogConfirmation = React.createRef();
	const countErrors = Object.keys(errorsObject || {}).length;

	React.useEffect(() => {
		const errorVariants = (errorVariant?.variants || []);
		const errorObject = {};
		([...variants] || []).map((variant) => {
			const index = variant.variantIndex;
			if (errorVariants[index]) {
				errorObject[index] = errorVariants[index];
			}
		});

		setErrorsObject(errorObject);
		if (Object.keys(errorObject).length > 0) {
			setIsOpen(true);
		}
	}, [errorVariant]);
  React.useEffect(() => {
    handleSetMinMaxPrice();
  }, []);

	const IconButton = isOpen ? KeyboardArrowUpIcon : KeyboardArrowDownIcon;
	const LabelButton = isOpen ? "Свернуть" : "Развернуть";

	// Логика по мульти изменению цены
	const handleChangeNamePrice = ({target}) => {
		const {value} = target;
		setNewPrice(value);
	}
	const handleChangeNamePriceDiscount = ({target}) => {
		const {value} = target;
		setNewPriceDiscount(value);
	}
	const handleSetNewPrices = (isConfirm) => {
		if (!isConfirm) {
			refDialogConfirmation.current.onOpen({
				title: "Подтверждение",
				message: `Применить цену: ${newPrice} для ${variants.length}шт.?`,
				acceptButtonAction: handleSetNewPrices.bind(this, true)
			})

			return
		}

		onMultiChangePriceChart(variants.map((t) => t.id), newPrice);
		setNewPrice("")
	}
	const handleSetNewPricesDiscount = (isConfirm) => {
		if (!isConfirm) {
			refDialogConfirmation.current.onOpen({
				title: "Подтверждение",
				message: `Применить цену: ${newPriceDiscount} для ${variants.length}шт.?`,
				acceptButtonAction: handleSetNewPricesDiscount.bind(this, true)
			})

			return
		}


		onMultiChangePriceDiscountChart(variants.map((t) => t.id), newPriceDiscount);
		setNewPriceDiscount("")
	}

	// Логика по мульти изменению статуса
	const handleChangeStatus = (event, value, isConfirm) => {
		if (!isConfirm) {
			refDialogConfirmation.current.onOpen({
				title: "Подтверждение",
				message: `${Boolean(value?.props?.value) ? 'Включить' : 'Выключить'} ${variants.length}шт.?`,
				acceptButtonAction: handleChangeStatus.bind(this, event, value, true)
			})

			return
		}

		onMultiChangeStatusChart(variants.map((t) => t.id), value?.props?.value);
	}

  // Установка минмальной и максимальной стоимости
  const handleSetMinMaxPrice = () => {
    const minBasePrice = numberFormat(Math.min(...(variants || []).map((t) => Number.parseFloat(t.price_old_value || 0))), 2, '.');
    const maxBasePrice = numberFormat(Math.max(...(variants || []).map((t) => Number.parseFloat(t.price_old_value))), 2, '.');

    const minDiscountPrice = numberFormat(Math.min(...(variants || []).map((t) => Number.parseFloat(t.price_value || 0))), 2, '.');
    const maxDiscountPrice = numberFormat(Math.max(...(variants || []).map((t) => Number.parseFloat(t.price_value || 0))), 2, '.');

    let _basePrice = `Базовая ${ minBasePrice }`;
    if (minBasePrice !== maxBasePrice) {
      _basePrice = `Базовая от ${ minBasePrice } до ${ maxBasePrice }`;
    }

    let _discountPrice = `Цена со скидкой ${ minDiscountPrice }`;
    if (minDiscountPrice !== maxDiscountPrice) {
      _discountPrice = `Цена со скидкой от ${ minDiscountPrice } до ${ maxDiscountPrice }`;
    }

    setBasePrice(_basePrice);
    setDiscountPrice(_discountPrice);
  }

	const _renderTableRow = (option) => {
		// if (!option.option_preset_disable_sync_status && !option.status) {
		// 	return null
		// }
		return (
			<ExtraOptionRow
				option={JSON.stringify(option)}
				errorVariant={errorsObject}
				extraOption={extraOption}

				disabledForm={isDisabledEdit}
				disabledFormPrice={isDisabledEditPrice}

				idxExtraOption={option.idxExtraOption}
				idxOption={option.idxOption}
				isOptionPresetGroup={isOptionPresetGroup}

				isVisibleDimensions={isVisibleDimensions}
				isVisibleLogisticianData={isVisibleLogisticianData}

				onChange={handleChangeOption}
				onDelete={handleDeleteOption}

				minimize
			/>
		)
	}

	return (
		<>

			<Box mb={2}>
				<Grid container justifyContent="space-between" flexDirection="column">
					<Grid item>
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item>
                <Typography variant="h4">
                  {item.value}

                  {Boolean(isOptionPresetGroup) && (
                    <>
                      <span> ({variants.length} шт.)</span>

                      {Boolean(countErrors > 0) && (
                        <span style={{
                          color: "red",
                          marginLeft: "8px"
                        }}>({countErrors} ошибок)</span>
                      )}
                    </>
                  )}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5">{basePrice}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5">{discountPrice}</Typography>
              </Grid>
            </Grid>
					</Grid>
					<Grid item sx={{marginTop: 3}}>
						<Grid container spacing={2} alignItems="center">
							<Grid item>
								<Grid container spacing={1}>
									<Grid item>
										<TextField
											value={newPriceDiscount}
											size="small"
											label="Цена со скидкой"
											placeholder="Введите"
											fullWidth
											InputProps={{
												inputComponent: CustomInputAmount
											}}
											inputProps={{
												scale: 2
											}}

											onChange={handleChangeNamePriceDiscount}
										/>
									</Grid>
									<Grid item>
										<Button
											variant="contained"
											onClick={handleSetNewPricesDiscount.bind(this, false)}
										>Установить цену</Button>
									</Grid>
								</Grid>
							</Grid>
							<Grid item><Divider orientation="vertical" sx={{height: 40, background: "#3855EC", width: 1}}/></Grid>
							<Grid item>
								<Grid container spacing={1}>
									<Grid item>
										<TextField
											value={newPrice}
											size="small"
											label="Базовая цена"
											placeholder="Введите"
											fullWidth
											InputProps={{
												inputComponent: CustomInputAmount
											}}
											inputProps={{
												scale: 2
											}}

											onChange={handleChangeNamePrice}
										/>
									</Grid>
									<Grid item>
										<Button
											variant="contained"
											onClick={handleSetNewPrices.bind(this, false)}
										>Установить цену</Button>
									</Grid>
								</Grid>
							</Grid>
							<Grid item><Divider orientation="vertical" sx={{height: 40, background: "#3855EC", width: 1}}/></Grid>
							<Grid item>
								<FormControl fullWidth sx={{width: 200}}>
									<InputLabel>Статус</InputLabel>
									<Select
										label="Статус"
										onChange={handleChangeStatus}
									>
										<MenuItem value={1}>Включить</MenuItem>
										<MenuItem value={0}>Выключить</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item><Divider orientation="vertical" sx={{height: 40, background: "#3855EC", width: 1}}/></Grid>
							<Grid item>
								<Button color="primary" variant="contained" onClick={() => setIsOpen(!isOpen)}>
									<IconButton/>
									{LabelButton}
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Box>

			{Boolean(isOpen) && (
				<Table className={classes.table}>
					<TableHead>
						<TableRow>
							<TableCell width={30}>Статус</TableCell>
							<TableCell>Заголовок</TableCell>
							<TableCell>Цена со скидкой</TableCell>
							<TableCell>Базовая цена</TableCell>
							<TableCell>Сорт.</TableCell>
							<TableCell>Это отдельный товар</TableCell>
							{Boolean(isVisibleLogisticianData) && (
								<>
									<TableCell>Вес упаковки, кг</TableCell>
									<TableCell>Объем упаковки, м3</TableCell>
									<TableCell>Кол-во мест трансп.</TableCell>
								</>
							)}
							{Boolean(isVisibleDimensions) && (
								<>
									<TableCell>Ш., см</TableCell>
									<TableCell>В., см</TableCell>
									<TableCell>Г., см</TableCell>
									<TableCell>Д., см</TableCell>
								</>
							)}
							<TableCell>Изображение</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(variants || []).map((option) => _renderTableRow(option))}
					</TableBody>
				</Table>
			)}

			<DialogConfirmation
				ref={refDialogConfirmation}
			/>
		</>
	)
})
const CustomInputAmount = ({inputRef, ...rest}) => (
	<IMaskInput
		ref={inputRef}
		{...rest}

		mask={Number}
		thousandsSeparator=""
		radix="."
		mapToRadix={[',']}
		scale={rest?.scale}
		signed={true}
	/>
)

const styles = {
	controls: {},
	controlsActions: {
		marginLeft: "auto"
	},
	controlsAction: {
		minWidth: 0,
		width: 36,
		height: 36
	},
	controlsActionText: {
		width: "auto"
	},

	table: {
		borderCollapse: "collapse",

		"& .MuiTableHead-root .MuiTableCell-root": {
			padding: "4px 6px",
			fontSize: 12,
			lineHeight: "14px",
			fontWeight: "500"
		},
		"& .MuiTableBody-root .MuiTableCell-root": {
			padding: "0 6px",
			fontSize: 14,
			lineHeight: "14px",
			fontWeight: "500"
		},
		"& .MuiTableBody-root .MuiTableRow-root": {
			backgroundColor: "white!important",
			borderBottom: "1px solid #E6E6E6",
			"&:hover": {
				backgroundColor: "#cbd2f8!important",
			}
		},

		"& .MuiTableBody-root .MuiTableRow-root:nth-child(2n)": {
			backgroundColor: "#F4F4F4!important",
		}
	}
}
ExtraOption = withStyles(styles)(ExtraOption)

export default ExtraOption
